var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.complete()
        },
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-5 mt-4", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-navbar",
                { staticClass: "sidenav", attrs: { fixed: "top" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { "align-v": "center" },
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "back-btn",
                                  attrs: {
                                    size: "lg",
                                    to: "/upload-document",
                                    variant: "link",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/back_arrow.svg"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "d-md-none text-center",
                              attrs: { cols: "6" },
                            },
                            [_vm._v("Summary")]
                          ),
                          _c("b-col", {
                            staticClass: "d-md-none text-right",
                            attrs: { cols: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "b-row",
                {
                  staticClass: "d-none d-sm-none d-md-flex",
                  staticStyle: {
                    position: "fixed",
                    height: "calc(100vh - 300px)",
                  },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-primary",
                      attrs: { cols: "12", "align-self": "start" },
                    },
                    [_vm._v(" Summary ")]
                  ),
                  _c("b-col", { attrs: { cols: "12", "align-self": "end" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "7" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h1", { staticClass: "mb-4 heading" }, [
                        _vm._v("Now check details."),
                      ]),
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: _vm.alert.show,
                            variant: _vm.alert.type,
                          },
                        },
                        [_vm._v(_vm._s(_vm.alert.message))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "small",
                        {
                          staticClass: "text-muted mb-2",
                          staticStyle: { display: "block" },
                        },
                        [_vm._v("Personal Details")]
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" First Name "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: {
                                        cols: "8",
                                        title: _vm.firstName,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.firstName) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Last Name "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: { cols: "8", title: _vm.lastName },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.lastName) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Gender "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: { cols: "8", title: _vm.gender },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.gender) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Email Address "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: { cols: "8", title: _vm.email },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.email) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Birthday "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: { cols: "8", title: _vm.birthday },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.birthday) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Phone Number "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: { cols: "8", title: _vm.phone },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.phone) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" State License "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: {
                                        cols: "8",
                                        title: _vm.licensestate,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.licensestate) + " "
                                      ),
                                    ]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" NPI Number "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: {
                                        cols: "8",
                                        title: _vm.npinumber,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.npinumber) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Fax Number "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-left bold text-truncate",
                                      attrs: {
                                        cols: "8",
                                        title: _vm.licensestate,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.faxno) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Street Address "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left bold",
                                      attrs: { cols: "8", title: _vm.address1 },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.address1) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Apartment/Suite "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left bold",
                                      attrs: { cols: "8", title: _vm.address2 },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.address2) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" City "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left bold",
                                      attrs: { cols: "8", title: _vm.city },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.city) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" State "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left bold",
                                      attrs: { cols: "8", title: _vm.state },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.state) + " ")]
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" Zip Code "),
                                  ]),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left bold",
                                      attrs: { cols: "8", title: _vm.zipcode },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.zipcode) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "small",
                        {
                          staticClass: "text-muted mb-2",
                          staticStyle: { display: "block" },
                        },
                        [_vm._v("Uploaded Files")]
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "h4",
                              { staticClass: "mb-4 bold text-primary" },
                              [_vm._v("Profile Image")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "avatar avatar-preview bg-primary rounded-circle",
                                staticStyle: { overflow: "hidden" },
                              },
                              [
                                _vm.avatarImg
                                  ? _c("img", {
                                      staticClass: "avatar-img recent",
                                      attrs: { src: _vm.avatarImg },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "h4",
                                { staticClass: "mb-4 bold text-primary" },
                                [_vm._v(" Licenses & Other documents ")]
                              ),
                              _vm._l(_vm.documents, function (document) {
                                return _c(
                                  "p",
                                  {
                                    key: document.id,
                                    staticClass: "text-muted mb-1",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(document.filename) + " "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "terms",
                            name: "checkbox-1",
                            value: "accepted",
                            "unchecked-value": "not_accepted",
                          },
                          model: {
                            value: _vm.terms,
                            callback: function ($$v) {
                              _vm.terms = $$v
                            },
                            expression: "terms",
                          },
                        },
                        [
                          _vm._v(" I have read and agree to these "),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.terms",
                                  modifiers: { terms: true },
                                },
                              ],
                              staticClass: "text-primary",
                              attrs: { href: "#" },
                            },
                            [_vm._v("Terms & Conditions")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: {
                            size: "lg",
                            pill: "",
                            type: "submit",
                            variant: "primary",
                            disabled: _vm.processing,
                          },
                        },
                        [_vm._v(_vm._s(_vm.processing ? "Saving" : "Continue"))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }