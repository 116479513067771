<template>
  <b-form @submit.prevent="complete()">
    <b-row align-h="center" class="mb-5 mt-4">
      <!-- half mobile half desktop -->
      <b-col cols="12">
        <b-navbar fixed="top" class="sidenav">
          <b-container fluid>
            <b-row align-v="center" style="width: 100%">
              <b-col cols="3">
                <b-button
                  class="back-btn"
                  size="lg"
                  to="/upload-document"
                  variant="link"
                  ><img src="@/assets/images/back_arrow.svg" alt=""
                /></b-button>
              </b-col>
              <b-col cols="6" class="d-md-none text-center">Summary</b-col>
              <b-col cols="3" class="d-md-none text-right">
                <!-- leave blank -->
              </b-col>
            </b-row>
          </b-container>
        </b-navbar>
      </b-col>
      <!-- desktop view -->
      <b-col cols="12" md="3">
        <b-row
          class="d-none d-sm-none d-md-flex"
          style="position: fixed; height: calc(100vh - 300px)"
        >
          <b-col cols="12" align-self="start" class="text-primary">
            Summary
          </b-col>
          <b-col cols="12" align-self="end">
            <!-- leave blank -->
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="7">
        <b-row>
          <b-col cols="12">
            <h1 class="mb-4 heading">Now check details.</h1>
            <b-alert :show="alert.show" :variant="alert.type">{{
              alert.message
            }}</b-alert>
          </b-col>
          <b-col cols="12" class="mb-5">
            <small class="text-muted mb-2" style="display: block"
              >Personal Details</small
            >
            <b-row>
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="4"> First Name </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="firstName"
                  >
                    {{ firstName }}
                  </b-col>
                  <b-col cols="4"> Last Name </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="lastName"
                  >
                    {{ lastName }}
                  </b-col>
                  <b-col cols="4"> Gender </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="gender"
                  >
                    {{ gender }}
                  </b-col>
                  <b-col cols="4"> Email Address </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="email"
                  >
                    {{ email }}
                  </b-col>
                  <b-col cols="4"> Birthday </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="birthday"
                  >
                    {{ birthday }}
                  </b-col>
                  <b-col cols="4"> Phone Number </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="phone"
                  >
                    {{ phone }}
                  </b-col>
                  <b-col cols="4"> State License </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="licensestate"
                  >
                    {{ licensestate }}
                  </b-col>
                  <b-col cols="4"> NPI Number </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="npinumber"
                  >
                    {{ npinumber }}
                  </b-col>
                  <b-col cols="4"> Fax Number </b-col>
                  <b-col
                    cols="8"
                    class="text-left bold text-truncate"
                    :title="licensestate"
                  >
                    {{ faxno }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="4"> Street Address </b-col>
                  <b-col cols="8" class="text-left bold" :title="address1">
                    {{ address1 }}
                  </b-col>
                  <b-col cols="4"> Apartment/Suite </b-col>
                  <b-col cols="8" class="text-left bold" :title="address2">
                    {{ address2 }}
                  </b-col>
                  <b-col cols="4"> City </b-col>
                  <b-col cols="8" class="text-left bold" :title="city">
                    {{ city }}
                  </b-col>
                  <b-col cols="4"> State </b-col>
                  <b-col cols="8" class="text-left bold" :title="state">
                    {{ state }}
                  </b-col>
                  <b-col cols="4"> Zip Code </b-col>
                  <b-col cols="8" class="text-left bold" :title="zipcode">
                    {{ zipcode }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mb-5">
            <small class="text-muted mb-2" style="display: block"
              >Uploaded Files</small
            >
            <b-row>
              <b-col cols="12" md="6">
                <h4 class="mb-4 bold text-primary">Profile Image</h4>
                <div
                  style="overflow: hidden"
                  class="avatar avatar-preview bg-primary rounded-circle"
                >
                  <img
                    v-if="avatarImg"
                    :src="avatarImg"
                    class="avatar-img recent"
                  />
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <h4 class="mb-4 bold text-primary">
                  Licenses &amp; Other documents
                </h4>
                <p
                  class="text-muted mb-1"
                  v-bind:key="document.id"
                  v-for="document in documents"
                >
                  {{ document.filename }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-checkbox
              id="terms"
              v-model="terms"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              I have read and agree to these
              <a href="#" v-b-modal.terms class="text-primary"
                >Terms &amp; Conditions</a
              >
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" class="text-right mt-3">
            <b-button
              size="lg"
              pill
              type="submit"
              variant="primary"
              :disabled="processing"
              style="min-width: 150px"
              >{{ processing ? "Saving" : "Continue" }}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12" md="12">
        <next-step step-number="1"></next-step>
      </b-col>
    </b-row> -->
  </b-form>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";

// import nextStep from "@/components/step/StepButton";
export default {
  name: "Summary",
  components: {
    // nextStep,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      birthday: "",
      gender: "",
      documents: [],
      avatarImg: null,
      terms: false,
      faxno: "",
      licensestate: "",
      state: "",
      npinumber: "",
      address1: "",
      address2: "",
      city: "",
      zipcode: "",
      processing: false,
      alert: {
        show: false,
        type: "info",
        message: "",
      },
    };
  },
  methods: {
    alertMessage(type, message) {
      window.scrollTo(0, 0);

      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },

    async complete() {
      try {
        this.processing = true;
        this.alert.show = false;
        if (this.terms == "accepted") {
          await OnboardingService.updateDoctorOnboarding(3);
          localStorage.setItem("isAuthenticated", true);
          this.$router.push("/");
        } else {
          return this.alertMessage(
            "warning",
            "Please see our terms an conditions first"
          );
        }
      } catch (error) {
        return this.alertMessage("warning", error.message);
      } finally {
        this.processing = false;
      }
    },
    getuser() {
      this.$store
        .dispatch("user/getUser")
        .then((data) => {
          if (data.onboardingstatus === "DONE") return this.$router.push("/");

          this.firstName = data.firstname;
          this.lastName = data.lastname;
          this.email = data.email;
          this.phone = data.phoneno;
          this.birthday = this.moment(data.birthday).format("MMMM D, YYYY");
          this.gender = data.gender;
          this.avatarImg = data.profileUrl;
          this.faxno = data.faxno;
          this.licensestate = data.licensestatename || data.licensestate;
          this.npinumber = data.npinumber;
          this.address1 = data.address1;
          this.address2 = data.address2 || "N/A";
          this.city = data.city;
          this.state = data.statename || data.state;
          this.country = data.country;
          this.zipcode = data.zipcode;

          this.$store
            .dispatch("user/getDocuments")
            .then((data) => {
              if (data.length > 0) {
                this.documents = data;
              }
            })
            .catch((err) => {
              if (err.message) {
                this.err = err.message;
              }
            });
        })
        .catch((error) => {
          if (error.message) {
            return this.alertMessage("warning", error.message);
          }
        });
    },
  },
  mounted() {
    this.getuser();
  },
};
</script>

<style lang="scss" scoped>
.avatar-preview {
  width: 9rem;
  height: 9rem;
}
</style>
